import React from "react";
import Layout from "../components/layout";
import SVG from "../icons/Thank-You.svg";
import styled from "@emotion/styled";
import BrandishLogo from "../icons/brandish-header-logo.svg";
import PageLink from "../components/PageLink";
import InnerContainer from "../components/InnerContainer";
import CtaButton from "../components/CtaButton";
import { Helmet } from "react-helmet";

const HeroComponent = styled.div`
  padding: 0;
`;
const HeroBar = styled.div`
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: ${props => props.theme.fonts.regular};
  color: #f4edeb;
  background-color: ${props => props.theme.colors.darkestPurple};
  @media (min-width: ${props => props.theme.breakpoints.sm}) {
    padding: 2rem 2rem 2rem 3rem;
  }

  .brandish-logo {
    width: 130px;
    @media screen and (min-width: ${props => props.theme.breakpoints.sm}) {
      width: 100%;
    }
  }
  a {
    text-decoration: none;
    color: #f4edeb;
  }

  p {
    font-size: 2.1rem;
    margin: 0 6rem 0 0;
  }
`;
const ContentContainer = styled.div`
  background-color: ${props => props.theme.colors.darkestPurple};
  padding: 2rem;
  @media screen and (min-width: ${props => props.theme.breakpoints.md}) {
    padding: 8rem 2rem;
  }
`;
const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
  @media screen and (min-width: ${props => props.theme.breakpoints.md}) {
    grid-template-columns: repeat(2, 1fr);
  }
`;
const Content = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media screen and (min-width: ${props => props.theme.breakpoints.md}) {
    text-align: left;
    padding: 4rem;
  }
`;

const ContentTitle = styled.h1`
  color: ${props => props.theme.colors.offWhite};
  font-family: ${props => props.theme.fonts.medium};
  font-size: 4rem;
  line-height: 5rem;
  margin-bottom: 1.2rem;
  @media screen and (min-width: ${props => props.theme.breakpoints.md}) {
    font-size: 6.5rem;
    line-height: 8rem;
    margin-bottom: 3.3rem;
  }
`;

const ContentSubtitle = styled.h2`
  color: ${props => props.theme.colors.offWhite};
  font-size: 1.8rem;
  line-height: 2.9rem;
  margin-bottom: 4.2rem;
  @media screen and (min-width: ${props => props.theme.breakpoints.md}) {
    font-size: 2.5rem;
    line-height: 3.9rem;
    margin-bottom: 5.8rem;
  }
`;

const Illustration = styled(SVG)`
  width: 100%;
  height: auto;
  margin-bottom: 2.4rem;
  @media screen and (min-width: ${props => props.theme.breakpoints.md}) {
    margin-bottom: 0;
  }
`;

const ButtonContainer = styled.div`
  margin-bottom: 4.7rem;
`;

const FormSubmissionThankYouPage = () => {
  return (
    <Layout>
      <Helmet
        htmlAttributes={{
          lang: "en",
        }}
        title={`Thank you for your submission - Brandish Agency`}
      />
      <HeroComponent>
        <HeroBar>
          <PageLink to="/">
            <BrandishLogo className="brandish-logo" />
          </PageLink>
          <p>{"Thanks"}</p>
        </HeroBar>
      </HeroComponent>
      <ContentContainer>
        <InnerContainer>
          <Container>
            <Illustration />
            <Content>
              <ContentTitle>Thank You!</ContentTitle>
              <ContentSubtitle>
                You made the first move! And we’re happy you did. Talk to you
                soon—promise!
              </ContentSubtitle>
              <ButtonContainer>
                <CtaButton
                  linkType={"page"}
                  type="solid"
                  color={`purple`}
                  link={{ title: "Back to Home", url: "/" }}
                />
              </ButtonContainer>
            </Content>
          </Container>
        </InnerContainer>
      </ContentContainer>
    </Layout>
  );
};

export default FormSubmissionThankYouPage;
